import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

const SingleStoreProduct = ({ product }) => {
  return (
    <Link key={product.productSlug} to={`/products/${product.productSlug}`}>
      <div className="rounded-lg overflow-hidden border border-amber-100 bg-amber-100">
        <div className="relative">
          <Img
            className="w-full lg:h-56 h-48  object-contain"
            fluid={product.image.fluid}
            alt="card-image"
          />
          {product.seasonOffer && (
            <p className="text-xs lg:text-sm xl:text-sm text-center font-light absolute top-2 left-0 bg-green-500 text-white tracking-wide px-3 py-1">
              OFFER
            </p>
          )}
        </div>
        <div className="pl-4 md:pl-6">
          <h1 className="md:text-lg xl:text-lg pt-5 pb-2 font-light">
            {product.name}
          </h1>

          <p className="text-lg lg:text-xl tracking-wide font-semibold text-amber-900  pb-3 md:pb-4 rounded-md">
            ${product.price}
          </p>
        </div>
      </div>
    </Link>
  )
}

export default SingleStoreProduct
