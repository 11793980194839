import React, { Component } from "react"
//import Img from "gatsby-image"
//import { Link } from "gatsby"
import SingleStoreProduct from "./SingleStoreProduct"

const getCategories = items => {
  let tempItems = items.map(items => {
    return items.node.category
  })
  let tempCategories = new Set(tempItems)
  let categories = Array.from(tempCategories)
  categories = ["All Products", ...categories]
  return categories
}

export default class StoreProducts extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: props.items.edges,
      productItems: props.items.edges,
      categories: getCategories(props.items.edges),
      value: "",
    }
  }

  handleItems = category => {
    let tempItems = [...this.state.items]
    if (category === "All Products") {
      this.setState(() => {
        return {
          productItems: tempItems,
        }
      })
    } else {
      let items = tempItems.filter(({ node }) => node.category === category)
      this.setState(() => {
        return {
          productItems: items,
        }
      })
    }
  }

  render() {
    if (this.state.items.length > 0) {
      return (
        <section className="my-8 md:my-10 lg:my-16 xl:my-12">
          <div className="xl:mx-5">
            <div className="lg:grid lg:grid-cols-12 mx-5 md:mx-10 lg:mx-12">
              <div className="lg:col-span-3 lg:mr-16 xl:mr-24 pb-10 lg:pb-0">
                {/*CATEGORIES*/}
                <div className="hidden lg:block lg:space-y-3">
                  {this.state.categories.map((category, index) => {
                    return (
                      <div
                        onClick={() => {
                          this.handleItems(category)
                          this.setState({ value: index })
                        }}
                        className=""
                      >
                        <h1
                          key={index}
                          className={`text-center px-5 py-3 hover:bg-amber-100 hover:text-black focus:outline-none transition-all duration-200 ease-linear cursor-pointer ${
                            index === this.state.value
                              ? "bg-amber-100 shadow-sm"
                              : "bg-amber-900 text-white"
                          }`}
                        >
                          {category}
                        </h1>
                      </div>
                    )
                  })}
                </div>
                {/*CATEGORIES - Mobile upto 768px*/}
                <div className="lg:hidden grid md:grid-cols-3 grid-cols-2 gap-1 md:gap-3 ">
                  {this.state.categories.map((category, index) => {
                    return (
                      <div
                        onClick={() => {
                          this.handleItems(category)
                          this.setState({ value: index })
                        }}
                        className=""
                      >
                        <h1
                          key={index}
                          className={`text-center px-5 py-3 hover:bg-amber-100 hover:text-black focus:outline-none transition-all duration-300 ease-linear cursor-pointer ${
                            index === this.state.value
                              ? "bg-amber-100 shadow-sm"
                              : "bg-amber-900 text-white"
                          }`}
                        >
                          {category}
                        </h1>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="lg:col-span-9 col-span-12">
                <div className="w-auto lg:w-full px-0 md:px-0 lg:px-0 xl:px-0 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-3 gap-y-5 md:gap-4 md:gap-y-7 lg:gap-4 lg:gap-y-8 xl:gap-6">
                  {this.state.productItems.map(({ node: product }) => {
                    return (
                      <SingleStoreProduct key={product.id} product={product} />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    } else {
      return (
        <section className="w-4/5">
          <div className="">
            <h1>There are no items to display.</h1>
          </div>
        </section>
      )
    }
  }
}
